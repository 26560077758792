import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  headers: {
    'x-name': process.env.REACT_APP_SUBDOMAIN,
  },
});

export const getSacramentProgram = async () => {
  return await instance.get('/sacrament-program');
};

export const getAnnouncements = async () => {
  return await instance.get('/announcements');
};
