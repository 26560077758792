import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Shared/Header';
import TithingDeclaration from '../../components/Shared/TithingDeclaration';
import {
  AnnouncementIcon,
  DonationIcon,
  FacebookIcon,
  FamilyHistoryIcon,
  MissionaryIcon,
  ProgramIcon,
  ServeIcon,
  TempleIcon,
} from '../../icons';
import styles from './styles.module.scss';

const Home = () => {
  const navigate = useNavigate();

  const images = [
    {
      name: 'christ',
      alt: 'Portrait of Jesus Christ',
    },
    {
      name: 'family',
      alt: 'A family studying the scriptures',
    },
    {
      name: 'nephites',
      alt: 'Jesus Christ visiting the Nephites in Ancient America',
    },
    {
      name: 'temple',
      alt: 'Payson, Utah Temple',
    },
  ];

  const pages = [
    {
      onClick: () => navigate(`/sacrament-program`),
      icon: <ProgramIcon />,
      name: 'Sacrament Program',
    },
    {
      onClick: () => navigate(`/announcements`),
      icon: <AnnouncementIcon />,
      name: 'Announcements',
    },
  ];

  const links = [
    {
      name: 'Ward Facebook Group',
      icon: <FacebookIcon />,
      href: `https://www.facebook.com/groups/1909751489230561/`,
    },
    {
      name: 'Temple Appointments',
      icon: <TempleIcon />,
      href: `https://www.churchofjesuschrist.org/temples/schedule/appointment?lang=eng`,
    },
    {
      name: 'Donations',
      icon: <DonationIcon />,
      href: `https://donations.churchofjesuschrist.org`,
    },
    {
      name: 'Volunteer and Serve',
      icon: <ServeIcon />,
      href: `https://www.churchofjesuschrist.org/serve?lang=eng`,
    },
    {
      name: 'FamilySearch.org',
      icon: <FamilyHistoryIcon />,
      href: `https://www.familysearch.org`,
    },
    {
      name: 'Missionary Portal',
      icon: <MissionaryIcon />,
      href: `https://missionary.churchofjesuschrist.org/portal/non-missionary?lang=eng`,
    },
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Welcome to the <div className={styles.wardName}>Summit Creek Ward</div>
      </h1>

      <h3 className={styles.subtitle}>Santaquin, Utah</h3>

      <div className={styles.images}>
        {images.map((img, index) => {
          return (
            <img
              src={`/images/${img.name}.png`}
              alt={img.alt}
              key={index}
              className={`${styles.image} ${styles[img.name]}`}
            />
          );
        })}
      </div>

      {/* <TithingDeclaration /> */}

      <div className={styles.spiritualThought}>
        <Header
          title='Spiritual Thought'
          direction='row'
          type='section'
          underlined
        />

        <div className={styles.thought}>
          “So many wonderful things are ahead. In coming days, we will see the
          greatest manifestations of the Savior's power that the world has ever
          seen. Between now and the time He returns 'with power and great
          glory', (Joseph Smith-Matthew 1:36), He will bestow countless
          privileges, blessings, and miracles upon the faithful.”
          <a
            className={styles.reference}
            href='https://www.churchofjesuschrist.org/learn/russell-m-nelson?lang=eng'
            target='_blank'
          >
            President Russell M. Nelson
          </a>
        </div>
      </div>

      <div className={styles.helpfulLinks}>
        <Header
          title='Helpful Links'
          direction='row'
          type='section'
          underlined
        />

        {pages.map((page, index) => {
          return (
            <Button
              key={index}
              onClick={page.onClick}
              className={styles.link}
              startIcon={page.icon}
            >
              {page.name}
            </Button>
          );
        })}

        {links.map((link, index) => {
          return (
            <Button
              href={link.href}
              target='_blank'
              key={index}
              className={styles.link}
              startIcon={link.icon}
            >
              {link.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
